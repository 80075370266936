import $ from "jquery";
import { SubscribeWithGoogleOptions } from "../config";

let subscribeWithGoogleLoaded = false;
$.loadSubscribeWithGoogle = function () {
  if (subscribeWithGoogleLoaded === true) {
    return;
  }

  subscribeWithGoogleLoaded = true;
  (self.SWG = self.SWG || []).push(function (subscriptions) {
    // Specifing the paySwgVersion backend parameter is required when
    // manually initializing swg.js for RRM:E
    subscriptions.configure({ paySwgVersion: "2" });

    // Either use a publication ID (example.com) or
    // a full product ID (example.com:premium).
    subscriptions.init(SubscribeWithGoogleOptions.productId);

    $.linkSubscriptionWithGoogle = function () {
      $.post(`/mon-compte/manage-swg-subscription-linking`, { action: "get-ppid" })
        .then(({ publisherProvidedId }) => {
          return subscriptions.linkSubscription({ publisherProvidedId: publisherProvidedId });
        })
        .then((result) => {
          console.log("Subscription linking result : " + JSON.stringify(result));

          return $.post(`/mon-compte/manage-swg-subscription-linking`, { action: "refresh-link" });
        })
        .then(() => {
          window.location.reload(); // Reload the page
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (tiggerSubscriptionLinkingAfterLoad) {
      $.linkSubscriptionWithGoogle();
    }
  });
};

let tiggerSubscriptionLinkingAfterLoad = false;
$.tiggerSubscriptionLinkingAfterLoad = function () {
  tiggerSubscriptionLinkingAfterLoad = true;
};
