/* globals FB:true */
import { GDPRConsent, languages, services } from "gdpr-consent";
import $ from "jquery";

// Load languages
const { getLanguages } = languages;
GDPRConsent.withLanguages(getLanguages);

// Load services
const { twitter, facebook, youtube, vimeo, facebookvideo, signinwithapple, signinwithgoogle, subscribewithgoogle } =
  services;
GDPRConsent.withServices((gdprConsentUser) => {
  return {
    twitter: twitter(gdprConsentUser),
    facebook: facebook(gdprConsentUser),
    youtube: youtube(gdprConsentUser),
    vimeo: vimeo(gdprConsentUser),
    facebookvideo: facebookvideo(gdprConsentUser),
    signinwithapple: signinwithapple(gdprConsentUser),
    signinwithgoogle: signinwithgoogle(gdprConsentUser),
    subscribewithgoogle: subscribewithgoogle(gdprConsentUser),
  };
});

export function showGdprBanner() {
  // Check if it's a preview
  if (window.LesJours.isPreview === true) {
    // Nothing to do
    return;
  }

  // Attach GDPRConsent to the window
  window.GDPRConsent = GDPRConsent;

  // Initialize gdpr-consent.js job array
  GDPRConsent.job = GDPRConsent.job || [];

  // Add Socials Sharing Services : Twitter & Facebook
  GDPRConsent.job.push("twitter");
  window.fbAsyncInit = function () {
    FB.init({ appId: "1716167151957741", status: true, version: "v19.0" });
  };
  GDPRConsent.job.push("facebook");

  // Add Video Services : Youtube, Vimeo & Facebook Video
  GDPRConsent.job.push("youtube");
  GDPRConsent.job.push("vimeo");
  GDPRConsent.job.push("facebookvideo");

  // Add Sign-in with Services
  GDPRConsent.user.signinwithappleMore = $.loadSignInWithApple;
  GDPRConsent.job.push("signinwithapple");
  GDPRConsent.user.signinwithgoogleMore = $.loadSignInWithGoogle;
  GDPRConsent.job.push("signinwithgoogle");

  // Check if Extended Access scripts are loaded
  if (window.LesJours.gaaMetering === undefined) {
    // Extended Access scripts are not loaded, we can add the Subscribe with Google Service
    GDPRConsent.user.subscribewithgoogleMore = $.loadSubscribeWithGoogle;
    GDPRConsent.job.push("subscribewithgoogle");
  }

  // Initialize the GDPR banner
  GDPRConsent.init({
    hashtag: "#tarteaucitron", // Open the panel with this hashtag
    cookieName: "tarteaucitron", // Cookie name
    timeExpire: 31536000000, // Cookie expiration time
    highPrivacy: false, //  Disable auto consent
    AcceptAllCta: true, //  Show the accept all button when highPrivacy on
    moreInfoLink: true, //  Show more info link
    mandatory: true, //  Show a message about mandatory cookies
    preferLocalStorage: true, // Use the localStorage to save responses if it's available

    /* Banner messages */
    websiteName: "Les Jours",
    siteDisclaimerTitle: "«&nbsp;Le site qui raconte l’actualité en séries&nbsp;»",
    siteDisclaimerMessage:
      '<i>Les&nbsp;Jours</i> sont un média <a href="/les-jours-c-quoi/">indépendant et sans pub</a> accessible par <a href="/abonnement/jouriste">abonnement</a>. Découvrez <a href="/les-jours-en-clair/">notre zone gratuite</a>&nbsp;: l’actualité en profondeur, avec des épisodes, des personnages, des lieux.',
  });
}
