import $ from "jquery";

// Variables
const $header = $("#header");
const $questions = $(".faq-element .faq-question");

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SCROLL TO FAQ ITEMS ON CLICS                                                                                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$questions.on("click.lesjours.faq", function (event) {
  // Get Elements
  const $question = $(event.currentTarget);
  const $faqElement = $question.parents(".faq-element");

  // Check if we are closing the element
  if ($faqElement.attr("aria-expanded") === "false") {
    // Wait 100ms before scrolling
    setTimeout(function () {
      // Move the Scroll to put the Question on the Top of the Window
      $("html, body").animate(
        {
          scrollTop: $faqElement.offset().top - $header.height(),
        },
        250,
        "swing"
      );
    }, 100);
  }
});
